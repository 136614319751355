import React, { useState, useEffect } from 'react';
import './App.css';
import logo from './cropchezdrewlogo.jpg';  // make sure to add your logo image to the src folder
import menu from './enhancedmenu.jpg';  // make sure to add your menu image to the src folder
import backgroundMusic from './tswift.mp3';  // import the audio file

function App() {
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    const audioElement = document.getElementById('background-audio');
    console.log('Audio volume:', audioElement.volume);
    console.log('Audio muted:', audioElement.muted);
  }, []);
  const handleLogoClick = () => {
    setShowMenu(!showMenu);
  };
  console.log({ backgroundMusic })
  return (
    <div className="App">
      <header className="App-header">
      <audio autoPlay loop id="background-audio">
          <source src={backgroundMusic} type="audio/mp3" />
          Your browser does not support the audio element.
        </audio>
        {showMenu ? (
          <img src={menu} className="Menu-image" alt="menu" onClick={handleLogoClick} />
        ) : (
          <img src={logo} className="App-logo" alt="logo" onClick={handleLogoClick} />
        )}
      </header>
    </div>
  );
}

export default App;